import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  },
  link: {
    color: '#5566E5',
    textDecoration: 'none',
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <Layout {...props}>
      <Seo title='About Swift on Arm' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Swift on Arm'
            subheader='Information and links to the many parts of the Swift 0n Arm project.'
          />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='How to Connect'>
                Join the swift-arm slack team &nbsp;-&nbsp; 
                <Link
                    variant='mute'
                    target='_blank'
                    title='Build Swift on Arm'
                    href='https://launchpass.com/swift-arm'
                    rel='noopener'
                    style={styles.link}
                  >
                     swift-arm.slack.com
                  </Link>
                  <br/>
                  Use the Swift Forums &nbsp;-&nbsp; 
                <Link
                    variant='mute'
                    target='_self'
                    title='Forums'
                    href='https://forums.swift.org/'
                    rel='noopener'
                    style={styles.link}
                  >
                     forums.swift.org
                  </Link>
              </Section>
              <Divider />
              <Section title='Swift on Arm 32bit'>
                Information about Swift on Arm/Armv6/Armv7/AArch32<br/>
                <Link
                    variant='mute'
                    target='_blank'
                    title='Build Swift on Arm'
                    href='https://github.com/uraimo/buildSwiftOnARM/'
                    rel='noopener'
                    style={styles.link}
                  >
                    https://github.com/uraimo/buildSwiftOnARM/
                  </Link>
              </Section>
              <Divider />
              <Section title='Swift on Arm64/AArch64'>
                  Information about Swift on Arm64/AArch64<br/>
                  <Link
                    variant='mute'
                    target='_blank'
                    title='Swift-Arm64'
                    href='https://github.com/futurejones/swift-arm64'
                    rel='noopener'
                    style={styles.link}
                  >
                    https://github.com/futurejones/swift-arm64
                  </Link>
              </Section>
              <Divider />
              <Section title='Swift Arm Community CI'>
                  The Swift Arm Community Hosted CI Server Project.<br/>
                  <Link
                    variant='mute'
                    target='_blank'
                    title='Swift-CI'
                    href='https://ci-external.swift.org/'
                    rel='noopener'
                    style={styles.link}
                  >
                    https://ci-external.swift.org/
                  </Link>
              </Section>
              <Divider />
              <Section title='Swift-Arm Community Releases'>
                  The Swift-Arm Package Repository.<br/>
                  <Link
                    variant='mute'
                    target='_blank'
                    title='Swift-Community-Repository'
                    href='https://packagecloud.io/swift-arm/release'
                    rel='noopener'
                    style={styles.link}
                  >
                    https://packagecloud.io/swift-arm/release
                  </Link>
                  <br/><br/>
                  The Swift Community Apt Repository.<br/>
                  <Link
                    variant='mute'
                    target='_blank'
                    title='Swift-Community-Repository'
                    href='https://swiftlang.xyz'
                    rel='noopener'
                    style={styles.link}
                  >
                    https://swiftlang.xyz
                  </Link>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
